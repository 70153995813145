<template>
  <div class="remember-success">
    <h3>
      Проверьте почту! <br>
      <span>Мы отправили вам новый&nbsp;пароль</span>
    </h3>

    <img
      src="/img/purchase-auth/remember-success.png"
      alt="success"
      class="remember-success__img"
    >

    <button
      class="m-btn remember-success__login"
      @click="$emit('login')"
    >
      войти еще раз
    </button>

    <div class="remember-success__soc">
      <p>напиши нам, если возникли трудности</p>
      <div>
        <a
          href="https://wa.me/79686286327"
          target="_blank"
        >
          <img
            src="/v2/school-university/footer/wa.png"
            alt="whatsapp"
          >
        </a>
        <a
          :href="$getTelegramLink($route)"
          target="_blank"
        >
          <img
            src="/v2/school-university/footer/tg.png"
            alt="telegram"
          >
        </a>
        <a
          href="mailto:BPrusakov@synergy.ru"
          target="_blank"
        >
          <img
            src="/v2/school-university/footer/email.png"
            alt="email"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PurchaseRememberSuccess',
};
</script>

<style scoped lang="scss">

.remember-success {
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: calc(var(--scale) * 40);

    font-weight: 500;
    font-size: calc(var(--scale) * 48);
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #02120F;

    @include media-down($size-tablet) {
      font-size: calc(var(--scale) * 28);
      line-height: 95%;
    }

    span {
      color: #A7ABAC;
    }
  }

  .remember-success__img {
    margin-inline: auto;
    margin-bottom: calc(var(--scale) * 40);
    width: calc(var(--scale) * 200);

    @include media-down($size-tablet) {
      width: calc(var(--scale) * 160);
    }
  }

  .remember-success__login {
    margin-bottom: calc(var(--scale) * 40);
    margin-inline: auto;

    font-weight: 400;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    text-decoration-line: underline;
    color: #FF5319;

    @include media-down($size-tablet) {
      margin-bottom: calc(var(--scale) * 40);

      font-weight: 500;
      font-size: calc(var(--scale) * 14);
    }
  }

  .remember-success__soc {
    margin-inline: auto;

    @include media-down($size-tablet) {
      padding-bottom: calc(var(--scale) * 10);
    }

    p {
      margin-bottom: calc(var(--scale) * 12);

      text-align: center;
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 18);
      }
    }

    div {
      display: flex;
      justify-content: center;
      gap: calc(var(--scale) * 8);

      img {
        width: calc(var(--scale) * 80);

        @include media-down($size-tablet) {
          width: calc(var(--scale) * 56);
        }
      }
    }
  }
}

</style>
