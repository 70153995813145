<template>
  <div class="lead lead-modal">
    <div class="lead__inner">
      <button
        class="lead__close m-btn"
        @click="closeModal"
      >
        <img
          src="/v2/modal/close-white.svg"
          alt="cross"
          class="lead__close-icon"
        >
      </button>

      <div
        class="lead__form"
        @keypress.enter="submit"
      >
        <h2 class="lead__title">
          Забронируйте
          <br>
          <span><span>максимальную</span></span> скидку!
          <br>
          Оставляйте заявку
        </h2>

        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="form__input"
          placeholder="Имя"
          round-border
        />

        <form-phone-field
          ref="phoneField"
          class="form__input form__input_phone"
          :errors-list="submitError"
          :error="validForm === false"
          round-border
          @updatePhone="phoneInput = $event"
        />

        <form-email-field
          ref="emailField"
          v-model="emailInput"
          class="form__input form__input_email"
          :submit-error="submitError"
          round-border
          placeholder="Почта"
          @clearSubmitError="clearSubmitError"
        />

        <div class="lead__form_submit m-btn__pull">
          <button
            class="m-btn"
            @click="submit"
          >
            Получить скидку
          </button>
        </div>

        <span class="lead__form_privacy">
          Нажимая на кнопку, вы соглашаетесь
          <nuxt-link
            to="/privacy"
            target="_blank"
          >
            с условиями обработки данных
          </nuxt-link>
        </span>

        <p class="lead__tx">
          Или напишите нам в чат:
        </p>

        <div class="contacts__row-bottom">
          <a
            class="contacts__link"
            href="https://wa.me/79686286327"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="contacts__soc-icon"
              src="/v2/modal/whatsapp.svg"
              alt="whatsapp"
            >
          </a>
          <a
            class="contacts__link"
            :href="$getTelegramLink($route)"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="contacts__soc-icon"
              src="/v2/modal/telegram.svg"
              alt="telegram"
            >
          </a>
          <a
            class="contacts__link"
            href="mailto:BPrusakov@synergy.ru"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="contacts__soc-icon"
              src="/v2/modal/email.svg"
              alt="email"
            >
          </a>
        </div>
      </div>
    </div>

    <div
      class="lead__overlay"
      @click="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';
import { getQuestionnairePath } from '@/utils/questionnairePath';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needEmail: true,
});

export default {
  name: 'NewYearModal',
  components: {
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],

  data: () => ({
    landCode: 'new_online_school_new_year_24_banner',
  }),

  computed: {
    ...mapGetters(['isTablet']),
  },

  mounted() {
    if (this.isTablet) {
      document.body.style.overflow = 'hidden';
    }
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },

  methods: {
    ...mapActions('modals', {
      closeModal: 'closeNewYear',
    }),
    onSubmit() {
      // this.$router.push('/thanks');
      this.$router.push(getQuestionnairePath(this.$route));
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.lead {
  .lead__inner {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;

    margin: auto;

    width: min(610px, calc(100% - 16px));
    height: fit-content;
    max-height: 95vh;

    border-radius: 20px;
    border: 2px solid $black;
    background-image: url("/v2/modal/new-year/bg.webp");
    background-color: #1a9b5f;
    background-size: cover;
    background-position: bottom center;
    box-shadow: 4px 4px 0 0 $black;

    overflow: hidden;

    @include media-down(tablet) {
      padding-top: 16px;
      padding-bottom: 40px;
      box-shadow: 2px 4px 0 0 $black;
    }

    @include media-down(mobile) {
      background-image: url("/v2/modal/new-year/bg-mob.webp");
      overflow: hidden;
    }
  }

  .lead__close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  .lead__title {
    margin-bottom: 40px;

    color: $white-color;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.2px;

    @include media-down(tablet) {
      margin-bottom: 20px;
      max-width: 410px;

      font-size: 24px;
    }

    & > span {
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 6px;
        background-color: #F52731;
        position: absolute;
        left: 0;
        bottom: 2px;

        @include media-down(tablet) {
        }
      }

      span {
        position: relative;
        z-index: 2;
      }
    }
  }

  .lead__form {
    position: relative;
    padding: 38px;

    @include media-down(tablet) {
      margin-top: 10px;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;

      padding: 12px 8px;
    }

    p {
      color: $white-color;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      margin-bottom: 30px;

      @include media-down(tablet) {
        text-align: center;
        margin-bottom: 24px;
        font-size: 20px;
        font-weight: 600;
        line-height: 100%;
        max-width: 330px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    ::v-deep .phone-field__wrap {
      border: none;
    }

    .form__input {
      margin-bottom: 10px;

      @include media-down(mobile) {
        margin-bottom: 8px;
      }

      ::v-deep input:not(.phone-field__input) {
        width: 100%;
        height: auto;
        padding: 20px 18px 22px;

        color: $black;
        font-size: 20px;
        letter-spacing: 0.4px;
        border-radius: 15px;
        border: none;

        @include media-down(mobile) {
          padding: 16px 14px 17px;
          font-size: 18px;
          letter-spacing: 0.36px;
        }
      }
    }

    .lead__form_submit {
      margin-top: 20px;
      margin-bottom: 10px;
      border-radius: 100px;

      @include media-down(mobile) {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      &::before {
        background-color: $white-color;
      }

      button {
        padding: 19px 20px 21px;
        border-radius: 100px;
        border: 2px solid $white-color;
        background-color: #F52731;
        color: $white-color;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-weight: 700;
        width: 100%;
        cursor: pointer;
        position: relative;

        @include media-down(mobile) {
          padding: 15px 10px 16px;
          font-size: 18px;
        }

        &:after {
          content: '';
          width: 7px;
          height: 21px;
          display: inline-block;
          background-image: url('/v2/modal/button-arrow-white.svg');
          margin-left: 5px;
        }
      }
    }

    .lead__form_privacy {
      text-align: center;
      color: $white-color;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 18px;
      font-weight: 600;
      line-height: 100%;

      margin-bottom: 2px;
      display: block;

      @include media-down(tablet) {
        text-align: center;
        margin-bottom: 36px;
        font-weight: 500;
      }

      button,
      a {
        font-size: 18px;
        display: inline-block;
        color: #9FE3FF;
      }
    }
  }

  .lead__tx {
    margin-top: 35px;

    text-align: center;

    color: $white-color;
    font-variant-numeric: lining-nums proportional-nums;

    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
  }

  .lead__overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(2.5px);
  }
}

.contacts {
  display: grid;

  @include media-down(tablet) {
    width: 100%;
  }

  &__top {
    display: flex;
    gap: 36px;

    margin-bottom: 40px;
  }

  &__logo-link {
    width: fit-content;

    transition: 0.2s ease-out;

    &:hover {
      opacity: 0.6;
    }
  }

  &__logo {
    display: block;

    width: 100%;

    @include media-down(tablet) {
      width: 111px;
    }
  }

  &__logo-skv {
    transition: 0.2s ease-out;

    @include media-down(tablet) {
      width: 35px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__phone {
    margin-bottom: 20px;

    width: fit-content;

    font-size: 35px;
    font-weight: 600;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      justify-self: center;

      font-size: 25px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__email {
    margin-bottom: 40px;

    width: fit-content;

    font-size: 24px;
    font-weight: 500;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      justify-self: center;

      margin-bottom: 30px;

      font-size: 18px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__msngr {
    @include media-down(tablet) {
      display: none;
    }
  }

  &__tx {
    margin-bottom: 12px;

    font-size: 20px;
    font-weight: 500;
    color: #a7a7a7;

    br {
      display: none;

      @include media-down(tablet) {
        display: block;
      }
    }
  }

  &__row-bottom {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  &__link {
    display: block;
  }

  &__soc-icon {
    position: relative;
    width: 50px;
    height: 50px;
    border: 2px solid $white-color;
    background-color: $white-color;
    border-radius: 10px;
    transition: 0.2s ease-out;
    box-shadow: 2px 2px 0 0 $white-color;

    &:hover {
      transform: translate(2px, 2px);
      box-shadow: none;
    }
  }
}

</style>
